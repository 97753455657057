import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Pictures = () => {

    var settings = {
      arrows: false,
      rtl: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };

    return (
        <div className="section" id="gallery">
            <div className="container">
                <div className="section-title">
                    <h3 className="text-light">تصاویری از محیط باهم</h3>
                </div>
                <Slider {...settings}>
                  <div><img src={window.location.origin + '/imgs/sc_1-min.jpg'} alt="" /></div>
                  <div><img src={window.location.origin + '/imgs/sc_2-min.jpg'} alt="" /></div>
                  <div><img src={window.location.origin + '/imgs/sc_3-min.jpg'} alt="" /></div>
                  <div><img src={window.location.origin + '/imgs/sc_4-min.jpg'} alt="" /></div>
                  <div><img src={window.location.origin + '/imgs/sc_5-min.jpg'} alt="" /></div>
                  <div><img src={window.location.origin + '/imgs/sc_6-min.jpg'} alt="" /></div>
                  <div><img src={window.location.origin + '/imgs/sc_7-min.jpg'} alt="" /></div>
                  <div><img src={window.location.origin + '/imgs/sc_8-min.jpg'} alt="" /></div>
                </Slider>
            </div>
        </div> 
    );
};

export default Pictures;