import React from 'react';
import { FaAssistiveListeningSystems } from 'react-icons/fa';

const SectionC = () => {
    return (
        <div className="section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-center">
                        <div>
                            <h2 className="text-light"><FaAssistiveListeningSystems /></h2>
                            <h2 className="text-right text-light">ما گوش شنوا داریم!</h2>
                            <p className="mb-4 text-right">هر جور انتقاد یا پیشنهاد سازنده ای که دارید رو با ما در میون بذارید. نظرات شما برای ما مهمه و می تونید مطمئن باشید تک تک اون ها با حوصله بررسی میشن. </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img src={window.location.origin + '/imgs/dualphone.png'} alt="dual phone" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionC;