import React from 'react';

const Logos = () => {
    return (
        <div className="client-logos py-5">
            <div className="container text-center">
                <img src={window.location.origin + '/imgs/client-logos.png'} alt="client logos" className="img-fluid"/>
            </div>
        </div>
    );
};

export default Logos;