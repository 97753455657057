import React from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class Login extends React.Component {

    constructor () {
        super();
        this.state = {firstAttemp: true};
        this.handleFirst = this.handleFirst.bind(this);
        this.handleSecond = this.handleSecond.bind(this);
    }

    handleFirst(event) {
        event.preventDefault();
        if(this.state.mobile && this.state.countryCode)
            fetch(localStorage.getItem('dlServer') + '?fnname=check_mobile&mobile=' + this.state.mobile,
                {
                    method: "POST",
                    body: JSON.stringify({pk: 'Baham', countryCode: this.state.countryCode})
                })
                .then(res => res.json())
                .then((data) => {
                    if(data.return.MID === "1") // User exist
                    {
                        this.setState(
                            {
                                firstAttemp: false,
                                Username: data.return.Data[0].Username,
                                Profile_Picture: data.return.Data[0].Profile_Picture
                            }
                        );
                    }
                    else // User NOT exist
                    {
                        // console.log('Must Sign Up!');
                    }
                        
            });
    }

    handleSecond(event) {
        event.preventDefault();
        if(this.state.password) {
            fetch(localStorage.getItem('dlServer') + '?fnname=login',
                {
                    method: "POST",
                    body: "username=" + this.state.Username + "&password=" + this.state.password,
                    headers: 
                    {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                .then(res => res.json())
                .then((data) => {
                    // Succesful login
                    if(data.error === 0) {
                        // console.log(data.return);
                    } else {
                        alert(data.str);
                    }
                });
        }
    }

    componentDidMount() {
        if(!localStorage.getItem('dlServer'))
        {
            let url = 'https://s24.ir-android.com/joke_city/v29/index.php?fnname=Who_Are_You_v2'; 
            fetch(url)
                .then(res => res.json())
                .then((data) => {
                    localStorage.setItem('upServer', data.upServer);  
                    localStorage.setItem('dlServer', data.dlServer);  
            });
        }

    }
    

    render(){        
        return (
            <header id="home" className="text-center">
                <Container>
                    { this.state.firstAttemp

                    ?   <Form onSubmit={this.handleFirst}> 
                            <Form.Group controlId="formBasicPhone">
                                <Form.Label>تلفن همراه</Form.Label>
                                <PhoneInput
                                countryCodeEditable={false}
                                excludeCountries={'il'}
                                country={'ir'}
                                onChange={(value, data, event, formattedValue) => this.setState(
                                    { mobile: '0' + value.slice(data.dialCode.length) ,  countryCode: '+' + data.dialCode}
                                )}
                                style={{direction: "ltr", width: "300px", margin: "0 auto"}}
                                />
                            </Form.Group>
                            <Button variant="info" type="submit" value="Submit">
                                بررسی
                            </Button>
                        </Form>
                    :   <Form onSubmit={this.handleSecond}> 
                            <Form.Group controlId="formShowGroup">
                                <img alt="" id="LoginOwnerPic" src={this.state.Profile_Picture} />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>رمز عبور</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="رمز عبور"
                                    onChange={(e) => this.setState(
                                        { password: e.target.value }
                                    )}
                                    style={{direction: "ltr", width: "300px", margin: "0 auto"}} />
                            </Form.Group>
                            <Button variant="info" type="submit" value="Submit">
                                ورود
                            </Button>
                        </Form> }
                </Container>
            </header>
        );
    }
}

export default Login;