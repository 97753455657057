import React from 'react';

// import NotFound from './notFound';
import { Card, Button, Tabs, Tab, Row, Col, Badge } from 'react-bootstrap';
import { FaHome, FaUniversity, FaUserGraduate, FaMedal, FaUserFriends, FaInfoCircle, FaRegIdCard, FaVenusMars, FaHeartbeat, FaPager, FaChalkboardTeacher, FaWarehouse } from 'react-icons/fa';
import ContentLoader from 'react-content-loader';
import { Helmet } from 'react-helmet';
// import Lottie from 'react-lottie';

class Users extends React.Component {

    constructor () {
        super()
        this.state = {}
    }

    componentDidMount() {
        fetch('https://backend.ba-ham.com/users/' + this.props.match.params.userName)
            .then(res => res.json())
            .then((data) => {
                this.setState({data: data});
                // console.log(data);
                
        });
        
        if(!localStorage.getItem('dlServer'))
        {
            let url = 'https://s24.ir-android.com/joke_city/v29/index.php?fnname=Who_Are_You_v2'; 
            fetch(url, {
                headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                }
          
                })
                .then(res => res.json())
                .then((data) => {
                    localStorage.setItem('upServer', data.upServer);  
                    localStorage.setItem('dlServer', data.dlServer);  
            });
        }
    }

    handleSelect = (key) => {
        if(!this.state.posts)
        {   
            if(key === 'posts') {
                fetch(`https://backend.ba-ham.com/users/posts/${this.state.data.Pic.user_id}`,
                {
                    method: "POST",
                    headers: 
                    {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                .then(res => res.json())
                .then((data) => {
                    // console.log(data);
                    this.setState({posts: data.posts});
                    // console.log(data.posts);
                    
                });
            }
        }
    }

    render(){
        const renderCover = ()=>{

            if (this.state.data) {
                if(this.state.data.Pic.cover_location.includes('jpg')) {
                    return <div className="card-header bg-white" style={{margin: "3px", backgroundImage: `url(${this.state.data.Pic.cover_location})`, backgroundPosition: "center", paddingTop: "60%", height: 0, borderRadius: "15px 15px 0 0"}}></div>
                }
            }
            
            return <div className="card-header bg-white" style={{margin: "3px", backgroundImage: `url(${'https://ba-ham.com/img/baham3.png'})`, backgroundPosition: "center", paddingTop: "60%", height: 0}}></div>
        }

        const renderPosts = () => {
            let posts = [];
            if (this.state.posts) 
            {
                this.state.posts.map((object, i) => {
                    // Check if videopic/multiImage
                    if(object.medialist.includes('||'))
                    // Check if multiImage
                        if(object.medialist.includes(',')) return posts.push(<a key={i} rel="noopener noreferrer" target="_blank" href={'post/' + this.state.data.Pic.user_id + '/' + object.MID }><Col><img className="postsInProfile" alt="" src={object.medialist.split('||')[1].split(',')[0]} /></Col></a>);
                    // Video image
                        else return posts.push(<a key={i} rel="noopener noreferrer" target="_blank" href={'post/' + this.state.data.Pic.user_id + '/' + object.MID }><Col><img className="postsInProfile" alt="" src={object.medialist.split('||')[1]} /></Col></a>)
                    // Single Image
                    else if(object.medialist.includes(',')) return posts.push(<a key={i} rel="noopener noreferrer" target="_blank" href={'post/' + this.state.data.Pic.user_id + '/' + object.MID }><Col><img className="postsInProfile" alt="" src={object.medialist.split(',')[0]} /></Col></a>);
                
                    if(object.MPic !== "") 
                        if(object.MPic.includes('http')) return posts.push(<a key={i} rel="noopener noreferrer" target="_blank" href={'post/' + this.state.data.Pic.user_id + '/' + object.MID }><Col><img className="postsInProfile" alt="" src={JSON.parse(object.MPic).cpic} /></Col></a>);
                        else return posts.push(<a key={i} rel="noopener noreferrer" target="_blank" href={'post/' + this.state.data.Pic.user_id + '/' + object.MID }><Col><img className="postsInProfile" alt="" src={'https://s4.ir-android.com/joke_city/ChanelLogo/tn_'  + JSON.parse(object.MPic).cpic} /></Col></a>);
                    else if(object.medialist !== '') return posts.push(<a key={i} rel="noopener noreferrer" target="_blank" href={'post/' + this.state.data.Pic.user_id + '/' + object.MID }><Col><img className="postsInProfile" alt="" src={(object.medialist.includes('http')) ? object.medialist : 'https://s4.ir-android.com/joke_city/messagepictures/' + this.state.data.Pic.user_id + '/' + this.state.data.Pic.user_id + '_' + object.medialist} /></Col></a>);
                    else return posts.push(<a key={i} rel="noopener noreferrer" target="_blank" href={'post/' + this.state.data.Pic.user_id + '/' + object.MID }><Col>{object.MText}</Col></a>);
                })
            }
            else 
                for(var i=0; i<6; i++) {
                    posts.push(<ContentLoader key={i}><rect x="45" y="30" rx="5" ry="5" width="100" height="50" /></ContentLoader>);
                }
            return posts;
        }

        if (this.state.data)
            return (
                <header id="home" className="text-center pb-4">
                    
                    <Helmet>
                        <meta property="og:title" content={ this.state.data.Pic.user_username } />
                        <meta property="og:description" content={ this.state.data.Pic.StatusText } />
                        <meta property="og:type" content='Profile' />
                        <meta property="og:image" content={
                            this.state.data.Pic.cover_location.includes('jpg') ?
                            this.state.data.Pic.cover_location :
                            'https://ba-ham.com/img/baham3.png'
                        } />
                        <meta property="og:site_name " content='شبکه اجتماعی باهم' />
                    </Helmet>
                    <center>
                        <Card className="text-dark text-center border-light postTopic">
                            {renderCover()}
                            <Card.Body className="bg-info-light border-light">
                                <img alt="" className="pimg" src={ this.state.data.Pic.PicLocation } onError={(e)=>{e.target.src='https://ba-ham.com/img/baham.png'}} />
                                <div className="bold-sans-bg text-gray mt-5 username">{ this.state.data.Pic.user_username }<br /></div>
                                <div className="sans text-gray-light">{ this.state.data.Pic.StatusText }<br />
                                </div>

                                <div className="container mt-4">
                                    <div className="row">
                                        <div className="col">
                                            <Card.Title className="numbers text-gray">{ this.state.data.CFollwer }</Card.Title>
                                            <Card.Title className="text-main-color bold-sans">دنبال کننده</Card.Title>
                                        </div>
                                        <div className="col">
                                            <Card.Title className="numbers text-gray">{ this.state.data.CFollowing }</Card.Title>
                                            <Card.Title className="text-main-color bold-sans">دنبال شونده</Card.Title>
                                        </div>
                                        <div className="col">
                                            {this.state.data.postCount ?
                                            <Card.Title className="numbers text-gray">{ this.state.data.postCount }</Card.Title>
                                            :
                                            <Card.Title className="numbers text-gray">-</Card.Title>
                                            }
                                            <Card.Title className="text-main-color bold-sans">تعداد پست ها</Card.Title>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <Tabs defaultActiveKey="profile" variant="pills" className="justify-content-center profilePills" onSelect={this.handleSelect} >
                                    <Tab eventKey="profile" title="پروفایل" className="medals text-center">
                                        <div className="wholeLine"><FaMedal />&nbsp;مدال ها</div>
                                        <ul className="myList text-center">
                                            { this.state.data.medals.map((medal, i) => {
                                                if(medal.MID === "1")
                                                    return <li key={i}><img alt="" className="medalKhas" src={ medal.MPic } width="65" height="65" /><p className="points">{ medal.MValue }<br />{ medal.MName }</p></li>;
                                                else if(medal.MID === "116" && medal.MValue2 < 1000)
                                                    return <li key={i}><img alt="" className="medal" src={ medal.MBackgroud } width="65" height="65" /><p className="TLikes">{ medal.MValue }<br />{ medal.MName }<br />لایک</p></li>;
                                                else if(medal.MID < 3000 && medal.MID > 2000)
                                                    return <li key={i}><img alt="" className="medal" src={ medal.MBackgroud } width="65" height="65" /><p className="points">{ medal.MValue }<br />{ medal.MName }</p></li>;
                                                else
                                                    return <li key={i}><img alt="" className="medal" src={ medal.MPic } width="65" height="65" /></li>;
                                            })}
                                        </ul>
                                        { (this.state.data.proflie && typeof this.state.data.proflie.map === 'function')
                                        ? 
                                            <div>
                                                <div className="wholeLine"><FaUserFriends />&nbsp;دوستان</div>
                                                <ul className="myList text-center">
                                                    { this.state.data.proflie.friends.map((friend, i) => {
                                                        return <li key={i}><img alt="" className="medal" src={friend.Profile_Picture} width="65" height="65" /><div>{friend.user_username}</div></li>
                                                    })}
                                                </ul>
                                            </div>
                                        : <ul></ul> }
                                        {/* { (typeof this.state.data.Chanels.map === 'function' && this.state.data.Chanels.length !== 0)
                                        ?   <div>
                                            <div className="wholeLine">کانال ها</div>
                                            <ul className="chList text-center">
                                                { this.state.data.Chanels.map((chanel, i) => {
                                                    // console.log(chanel);
                                                    if(chanel.cpic) return <li key={i}><div className="chName"><div>{chanel.cname}</div></div><img alt="" className="medalKhas" src={(chanel.cpic.includes('http')) ? chanel.cpic : 'https://s4.ir-android.com/joke_city/ChanelLogo/' + chanel.cpic} width="175" height="90" /></li>;
                                                    else return '';
                                                })}
                                            </ul>
                                            </div>
                                        :   <p></p>} */}
                                        <div className="wholeLine text-center"><FaInfoCircle />&nbsp;اطلاعات پایه</div>
                                        <div className="baseInfo text-center">
                                            <Badge variant="success"><FaVenusMars />&nbsp;&nbsp; {(this.state.data.Pic.user_gender === 'M') ? 'آقا' : 'خانم'}</Badge>&nbsp;&nbsp;
                                        {(this.state.data.proflie) 
                                        ? 
                                            <Badge variant="danger"><FaHeartbeat />&nbsp;&nbsp; {(this.state.data.proflie.Status === '0') ? 'مجرد' : (this.state.data.proflie.Status === '1') ? 'نامزد دارم.' : 'متاهل'}</Badge>
                                        : <p></p>
                                        }
                                        </div>
                                        { (this.state.data.proflie && (this.state.data.proflie.Work !== '' || this.state.data.proflie.Skills !== '' || this.state.data.proflie.WorkPlace !== ''))
                                        ?
                                        <ul className="baseInfo text-center">
                                        <div className="wholeLine"><FaRegIdCard />&nbsp;شغل</div>
                                        { (this.state.data.proflie && this.state.data.proflie.Work !== '') 
                                        ? <i><Badge variant="primary"><FaPager />&nbsp;&nbsp; { this.state.data.proflie.Work }</Badge>&nbsp;&nbsp;</i>
                                        :   <p></p> }
                                        { (this.state.data.proflie && this.state.data.proflie.Skills !== '') 
                                        ? <i><Badge variant="secondary"><FaChalkboardTeacher />&nbsp;&nbsp; { this.state.data.proflie.Skills }</Badge>&nbsp;&nbsp;</i>
                                        :   <p></p> }
                                        { (this.state.data.proflie && this.state.data.proflie.WorkPlace !== '') 
                                        ?   
                                            <Badge variant="dark"><FaWarehouse />&nbsp;&nbsp; { this.state.data.proflie.WorkPlace }</Badge>                                        
                                        :   <p></p> }
                                        </ul>
                                        : <i></i>}
                                        { (this.state.data.proflie && this.state.data.proflie.fStudy !== '') 
                                        ?   <div>
                                            <div className="wholeLine"><FaUniversity />&nbsp;تحصیلات</div>
                                            <ul className="baseInfo text-center">
                                                <Badge variant="warning"><FaUserGraduate />&nbsp;&nbsp; { this.state.data.proflie.fStudy }</Badge>
                                                </ul>
                                            </div>
                                            :   <p></p> }
                                        { (this.state.data.proflie && (this.state.data.proflie.City || this.state.data.proflie.State !== ''))
                                        ?
                                        <ul className="baseInfo text-center">
                                        <div className="wholeLine"><FaHome />&nbsp;محل زندگی</div>
                                        { (this.state.data.proflie && this.state.data.proflie.State !== '') 
                                        ?   
                                                <Badge variant="primary"><strong>استان</strong>&nbsp;&nbsp; { this.state.data.proflie.State }</Badge>
                                            
                                        :   <p></p> }&nbsp;&nbsp;
                                        { (this.state.data.proflie && this.state.data.proflie.City !== '') 
                                        ?   
                                                <Badge variant="primary"><strong>شهر</strong>&nbsp;&nbsp; { this.state.data.proflie.City }</Badge>
                                            
                                        :   <p></p> }
                                        </ul>
                                        : <i></i> }
                                    </Tab>
                                    <Tab eventKey="posts" title="پست ها">
                                        <Row sm={3} xs={3} md={3} lg={3}>
                                            {renderPosts()}
                                        </Row>
                                    </Tab>
                                </Tabs>
                                <br />
                                <div className="row" style={{bottom: 0, margin: "3px"}}>
                                    <Button size="lg" variant="info" className="col-md-6 col-sm-6 col-6 btnRight" href="/#download">دانلود باهم</Button>
                                    <Button size="lg" variant="primary" className="col-md-6 col-sm-6 col-6 btnLeft" href={ 'baham://ba-ham.com/' + this.state.data.Pic.user_username }>بازکردن در باهم</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </center>
                </header>
            ) 
        else return (
            <header id="home" className="text-center pb-4">
                <center>
                <Card className="text-dark text-center border-light">
                    {renderCover()}
                    <Card.Body className="bg-info-light border-light">
                        <img alt="" className="pimg" src='https://ba-ham.com/img/baham.png' />
                        <div className="bold-sans-bg text-gray mt-5 username">شبکه اجتماعی باهم<br /></div>
                        {(this.state.data === 0)
                        ?
                            <div className="sans text-gray-light">کاربر مورد نظر وجود ندارد.
                            <br /><br /></ div>
                        :
                        <ContentLoader>
                            {/* Only SVG shapes */}    
                            <rect x="20" y="40" rx="3" ry="3" width="250" height="10" />
                            <rect x="20" y="70" rx="3" ry="3" width="250" height="10" />
                            <rect x="20" y="100" rx="3" ry="3" width="250" height="10" />
                        </ContentLoader>
                        }
                    </Card.Body>
                </Card>
                </center>
            </header>
        );
    }
}

export default Users;