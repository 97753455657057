import React from 'react';

import Contact from './contact';
import Address from './address';
import About from './about';

class Page extends React.Component {

    constructor () {
        super()
        this.state = {data: {'description': 'Loading'}, 'headData': 'لطفا صبر کنید!'}
    }

    componentDidMount() {
        if(this.props.location.pathname.replace('/page/', '') === 'rules')
        {
            fetch('https://backend.ba-ham.com/pages/Get_Description')
                .then(res => res.json())
                .then((data) => {
                    this.setState({data: {'description': data}, 'headData': 'قوانین و مقررات باهم'});
                });
        }
        else if(this.props.location.pathname.replace('/page/', '') === 'report')
        {
            this.setState({data: {'description': <Contact />}, 'headData': 'گزارش مشکلات'});
        }
        else if(this.props.location.pathname.replace('/page/', '') === 'about-us')
        {
            this.setState({data: {'description': <About />}, 'headData': 'درباره ما'});
        }
        else if(this.props.location.pathname.replace('/page/', '') === 'contact-us')
        {
            this.setState({data: {'description': <Address />}, 'headData': 'تماس با ما'});
        }
        else
        {
            this.setState({data: {'description': 'صفحه مورد نظر شما وجود ندارد!'}, 'headData': '404'});
        }
    }
    
    render(){
        return (
            <header>
                <div className="container bg-light px-5 pt-4 pb-1 mb-5 rounded">
                <h3 className="text-center text-dark">{this.state.headData}</h3> 
                <div className="row">
                    <div className="col-sm">
                        <div className="text-dark text-right" style={{whiteSpace: 'pre-line'}}>
                            {
                            this.state.data 
                                ? (<div>{this.state.data.description}</div>) 
                                : (<span></span>)
                            }<br />
                        </div>
                    </div>
                </div>
            </div>
            </header>
            );
    }
}

export default Page;