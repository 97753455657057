import React from 'react';

const Header = () => {
    return (
        <header id="home">
            <div className="container mt-5">
                <h1>شبکه اجتماعی باهم</h1>
                <p className="tagline">محیطی شاد و سالم برای با هم بودن! تو این شبکه اجتماعی ایرانی می تونید پست هایی همراه با عکس و کلیپ ارسال کنید و پست های دوستانتان را لایک کنید ! کامنت بزارید ! دوستانتان را دنبال کنید و با هم چت کنید... ! و هزارتا امکان دیگه!</p>
            </div>
            <div className="img-holder mt-3"><img src={window.location.origin + '/imgs/iphonex.png'} alt="phone" className="img-fluid" /></div>
        </header>
    );
};

export default Header;
