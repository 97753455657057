import React from 'react';
import { FaSmileBeam, FaCog, FaLock } from 'react-icons/fa';

const SectionA = () => {
    return (
        <div className="section" id="features">
            <div className="container">
                <div className="section-title">
                    <h3 className="text-light">هر آنچه شما دوست دارید!</h3>
                    <div className="row justify-content-around mt-5">
                        <div className="col-md-4 mt-3">
                            <div className="features">
                                <div className="media">
                                    <h1 className="text-dark"><FaSmileBeam className="ml-3 ti-3x" value={{ style: { verticalAlign: 'middle' } }} /></h1>
                                    <div className="media-body">
                                        <h4 className="card-title text-right text-light">صدها هزار کاربر فعال!</h4>
                                        <p className="card-text text-right">با بیش از یک دهه سابقه فعالیت ، افتخار همراهی هزاران پارسی زبان رو در کارنامه مون داریم.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="features">
                                <div className="media">
                                    <h1 className="text-dark"><FaCog className="ml-3 ti-3x gradient-fill" /></h1>
                                    {/* <span className="ti-settings gradient-fill ti-3x mr-3"></span> */}
                                    <div className="media-body">
                                        <h4 className="card-title text-right text-light">پایداری و بروزرسانی مداوم</h4>
                                        <p className="card-text text-right">افزودن امکانات جدید و بروزرسانی های مداوم ، رسالت اصلی تیم برنامه نویسی ماست! </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="features">
                                <div className="media">
                                    <h1 className="text-dark"><FaLock className="ml-3 ti-3x gradient-fill" /></h1>
                                    <div className="media-body">
                                        <h4 className="card-title text-right text-light">ایمن</h4>
                                        <p className="card-text text-right">باهم محیطی امن، سالم و بانشاط فراهم آورده تا خاطرات خوشی رو برای شما و دوستاتون به جا بذاره!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionA;