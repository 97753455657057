import React from 'react';

import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home/main';
import Page from './components/page/main';
import Login from './components/app/login';
import Users from './components/app/users';
import Posts from './components/app/posts';
import Channels from './components/app/channels';
import Groups from './components/app/groups';
import {Route, Switch} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Container } from 'react-bootstrap';

import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "./store/reducer"


const store = createStore(reducer);

// const isLoggedIn = (par) => {
// 	if(par.token === null || par.token === '0' || par.token === '')
// 		return false;
// 	else
// 		return true;
// }

// const PrivateRoute = ({ isLoggedIn, ...props }) => {
//   return isLoggedIn
//     ? <Route { ...props } />
//     : <Redirect to="/login" />
// }

class App extends React.Component {
	report = (id, cause) => {
		fetch('https://backend.ba-ham.com/report/post/' + id + '/' + cause, {
			method: 'post',
			headers: {'Content-Type':'application/json'},
		}).then(() => {
			alert('پست با موفقیت گزارش شد. از همکاری شما سپاسگزاریم.');
		});
	}

	render()
	{
		return (
			<Provider store={store}>
				<Container fluid id="top" className="pl-0 pr-0">
					
						<Header />
						<Switch>
							<Route exact={true} path="/" component={Home} />
							<Route exact={true} path="/login" component={Login} />
							<Route path="/page/:pageName" component={Page} />
							<Route path="/post/:userName/:postId" component={(props) => <Posts {...props} report={this.report} />} />
							<Route path="/ch/:chName" component={Channels} />
							<Route path="/grouplink/:grouplink" component={Groups} />
							<Route path="/:userName" component={Users} />
						</Switch>

				</Container>
				<div id="push"></div>
				<Footer />
			</Provider>
		);
	}
}

export default App;
