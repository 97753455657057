import React from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';

class Contact extends React.Component {
    constructor() {
        super();
        this.sendMessage = this.sendMessage.bind(this);
        this.state = { 'loading': false }
    }

    sendMessage(event) {
        this.setState({ 'loading': true });
        event.preventDefault();
        const data = new FormData(event.target);

        // var usermail = data.get('mail');
        // var username = data.get('uname');
        // var messagetext = data.get('textf');

        fetch('https://backend.ba-ham.com/forms/contactus', {
            method: 'POST',
            body: data,
        })
            .then(res => res.json())
            .then((data) => {
                this.setState({ 'loading': false });
                // this.setState({data: data});
                if (data.success) {
                    alert('پیام شما با موفقیت ثبت شد. به شما اطمینان می دهیم در اولین فرصت همکاران ما پاسخگوی شما خواهند بود.');
                    window.location.href = "/";
                }

            });
    };

    render() {
        if (this.state.loading === true)
            return (<div style={{width: "60px", height: "60px", margin: "0 auto"}}><br /><Spinner animation="border" role="status"></Spinner></div>)
        else
            return (<div>
                <div>همراهان گرامی باهم، لطفا در نظر داشته باشید پشتیبانی باهم در منوی اصلی برنامه همواره پاسخ گوی مشکلات شما عزیزان می باشد. چنانچه در حال حاضر اپلیکیشن باهم را نصب نکرده اید ، می توانید مشکلات و نقطه نظرات خود را ااز طریق فرم پایین نیز با ما در میان بگذارید:</div>
                <hr />
                <Form onSubmit={this.sendMessage}>
                    <Form.Group controlId="formEmai">
                        <Form.Label>لطفا ایمیل خود را وارد کنید:</Form.Label>
                        <Form.Control name="mail" type="email" placeholder="name@example.com" />
                    </Form.Group>
                    <Form.Group controlId="formUName">
                        <Form.Label>در صورت تمایل نام خود را وارد کنید:</Form.Label>
                        <Form.Control name="uname" type="text" placeholder="امیر آقایی" />
                    </Form.Group>
                    <Form.Group controlId="formTextf">
                        <Form.Label>متن پیام را وارد کنید:</Form.Label>
                        <Form.Control name="textf" as="textarea" rows={3} placeholder="متن درخواست" required/>
                    </Form.Group>
                    <Button variant="primary" type="submit" style={{ width: "100%" }}>
                        ارسال
                    </Button>
                </Form>
            </div>)
    }
}

export default Contact;