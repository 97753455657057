import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';

class Header extends React.Component {

    constructor () {
        super()
        this.state = {header: true, oldValue: 0}
    }

    componentDidMount() {
        window.addEventListener("scroll", this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    }

    
    onScroll = (e) => {
        var newValue = window.pageYOffset;
        
        //Subtract the two and conclude
        if(this.state.oldValue - newValue < 0){
            this.setState({header: false});
        } else if(this.state.oldValue - newValue > 0){
            this.setState({header: true});
        }
        this.setState({oldValue: newValue});
    };

    render(){
        return (
            <Navbar className={this.state.header ? 'navbar' : 'navHide'} show="false" fixed="top" expand="lg" variant="dark" style={{backgroundColor: "#693A92", transition: '1s ease'}}>
                <Navbar.Brand href="/"><img height="45" width="85" alt="" src={window.location.origin + '/imgs/logo.png'} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                    <Nav.Link href="/#top">خانه</Nav.Link>
                    <Nav.Link href="/#gallery">تصاویر</Nav.Link>
                    <Nav.Link href="/#faq">سوالات متداول</Nav.Link>
                    <Nav.Link href="/page/report">گزارش مشکلات</Nav.Link>
                    <Nav.Link href="/page/contact-us">تماس با ما</Nav.Link>
                    <Nav.Link href="/page/about-us">درباره ما</Nav.Link>
                    <Nav.Link href="/page/rules">قوانین</Nav.Link>
                    </Nav>
                    <Nav>
                    <Nav.Link eventKey={2} href="/#download">
                        <Button variant="outline-light">دانلود باهم</Button>
                    </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Header;