import React from 'react';

const Faq = () => {
    return (
        <div className="section light-bg" id="faq">
            <div className="container">
                <div className="section-title">
                    <small>FAQ</small>
                    <h3>سوالات متداول</h3>
                </div>

                <div className="row pt-4">
                    <div className="col-md-6">
                        <h4 className="mb-3 text-right">آیا برای نصب و استفاده از باهم ، باید مبلغی پرداخت کنم؟</h4>
                        <p className="mb-5 text-right">خیر! استفاده از شبکه اجتماعی باهم کاملا رایگان است. </p>
                        <h4 className="mb-3 text-right">چطور می توانم دو حساب کاربری داشته باشم؟</h4>
                        <p className="mb-5 text-right">اگر می خواهید با یک شماره موبایل بتوانید دو حساب کاربری ایجاد و تایید نمایید ، باید از باهم آبی استفاده کنید. </p>
                    </div>
                    <div className="col-md-6">
                        <h4 className="mb-3 text-right">چرا نمی توانم نام حساب کاربری خودمو تغییر بدم؟</h4>
                        <p className="mb-5 text-right">امکان تغییر نام مختص کاربران طلایی می باشد. </p>
                        <h4 className="mb-3 text-right">چرا پیامک برنامه به دست من نمی رسد؟</h4>
                        <p className="mb-5 text-right">گاهی به دلیل ایجاد اختلال در سیستم های مخابراتی ، پیامک های نرم افزار به دست شما نمی رسد. لطفا صبور باشید و ساعاتی بعد مجددا امتحان کنید. </p>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default Faq;