import React from "react";

const Footer = () => {
  return (
    <footer className="text-center pagefoot">
        <p className="mb-0 text-light"><small>تمامی حقوق محفوظ است.</small></p>
    </footer>
  );
}

export default Footer;