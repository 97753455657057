import React from 'react';
import { Card, Modal, Button, Form } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContentLoader from 'react-content-loader';
import { Helmet } from 'react-helmet';

class Posts extends React.Component {

    constructor() {
        super();
        this.state = {
            show: false,
            cause: "none"
        }
    }

    componentDidMount() {
        let url = 'https://backend.ba-ham.com/post/' + this.props.match.params.userName + '/' + this.props.match.params.postId;
        // console.log(url);
        fetch(url)
            .then(res => res.json())
            .then((data) => {
                this.setState({ 'data': data });
                // console.log(this.state);
            });

    }

    sendReport = () => {
        this.props.report(this.props.match.params.postId, this.state.cause);
        this.handleClose();
    }

    onChangeCause = (event) => {
        this.setState({
            cause: event.target.value
        });
    }

    handleClose = () => {
        this.setState({
            show: !this.state.show
        });
    }

    render() {
        var settings = {
            rtl: true,
        };

        const renderView = () => {
            if (Array.isArray(this.state.data[0].medialist)) {
                return (<Slider {...settings}>
                    {this.state.data[0].medialist.map((media, i) => {
                        return (<div key={i}><img src={media} alt="" style={{ width: "100%" }} /></div>);
                    })}
                </Slider>);

            } else {
                if (this.state.data[0].MVideo !== '') {
                    return <video controls><source src={this.state.data[0].MVideo} type="video/mp4" /></video>;
                }
                else if (this.state.data[0].MPic) {
                    // console.log(this.state.data[0].MPic);
                    if (JSON.parse(this.state.data[0].MPic).cpic.includes('http'))
                        return <div className="container mt-4" style={{ backgroundImage: `url(${JSON.parse(this.state.data[0].MPic).cpic})`, backgroundPosition: "center", height: 0, backgroundRepeat: "no-repeat", paddingTop: "100%", backgroundSize: "contain" }}></div>;
                    else
                        return <div className="container mt-4" style={{ backgroundImage: `url(https://s4.ir-android.com/joke_city/ChanelLogo/${JSON.parse(this.state.data[0].MPic).cpic})`, backgroundPosition: "center", height: 0, backgroundRepeat: "no-repeat", paddingTop: "100%", backgroundSize: "contain" }}></div>;
                }
                else if (this.state.data[0].medialist) {
                    if (this.state.data[0].medialist.includes('http'))
                        return <div className="container mt-4" style={{ backgroundImage: `url(${this.state.data[0].medialist})`, backgroundPosition: "center", height: 0, backgroundRepeat: "no-repeat", paddingTop: "100%", backgroundSize: "contain" }}></div>;
                    else
                        return <div className="container mt-4" style={{ backgroundImage: `url(https://s4.ir-android.com/joke_city/messagepictures/${this.state.data[0].MOwnerID}/${this.state.data[0].MOwnerID}_${this.state.data[0].medialist})`, backgroundPosition: "center", height: 0, backgroundRepeat: "no-repeat", paddingTop: "100%", backgroundSize: "contain" }}></div>;
                }
            }
        }

        const postImage = () => {
            if (Array.isArray(this.state.data[0].medialist)) {
                return this.state.data[0].medialist[0];
            } else {
                if (this.state.data[0].MVideo !== '') {
                    return this.state.data[0].MVideo;
                } else if (this.state.data[0].MPic) {
                    if (JSON.parse(this.state.data[0].MPic).cpic.includes('http'))
                        return JSON.parse(this.state.data[0].MPic).cpic;
                    else
                        return `https://s4.ir-android.com/joke_city/ChanelLogo/${JSON.parse(this.state.data[0].MPic).cpic}`;
                }
            }
        }

        // console.log(this.state.data);
        if (this.state.data) {
            return (
                <div>
                    <Helmet>
                        <meta property="og:title" content={ this.state.data[0].MOwnerName } />
                        <meta property="og:description" content={ this.state.data[0].MText } />
                        <meta property="og:type" content='Channel' />
                        <meta property="og:image" content={ postImage() } />
                        <meta property="og:site_name " content='شبکه اجتماعی باهم' />
                    </Helmet>
                    <Modal show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header>
                            <Modal.Title>گزارش پست</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'right' }}>
                            <p style={{marginBottom: "15px", color: "black"}}>در صورتی که محتوای این پست نامناسب است با گزارش آن ما را در بهبود کیفیت مطالب یاری کنید.</p>
                            لطفا علت گزارش پست را انتخاب کنید:
                            <Form.Control
                                as="select"
                                custom
                                onChange={this.onChangeCause.bind(this)}
                                >
                                <option value="none"> سایر </option>
                                <option value="por">محتوای نامناسب</option>
                                <option value="blas">توهین به مقدسات</option>
                                <option value="abu">سوءاستفاده</option>
                                <option value="self">صدمه به خود</option>
                                <option value="noco">بدون محتوا</option>
                            </Form.Control>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                صرف نظر
                        </Button>
                            <Button variant="danger" onClick={this.sendReport}>
                                ارسال گزارش
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    <header id="home" className="text-center">
                        <center>
                            <Card className="text-dark text-center border-light">
                                <Card.Header className="bg-white post-card-header">
                                    <a href={'/' + this.state.data[0].MOwnerName}><img alt="" id="MOwnerPic" src={this.state.data[0].MOwnerPic} />
                                        <div className="MOwnerInfo">
                                            <p id="MOwnerName">{this.state.data[0].MOwnerName}</p>
                                            <p id="MTime">{this.state.data[0].MTime}</p>
                                        </div>
                                    </a>
                                </Card.Header>
                                <div className="container text-right post-text">
                                    {this.state.data[0].MText}
                                </div>
                                <i data-toggle="tooltip" title="گزارش!" id="abuse" className="fa fa-2x fa-exclamation-triangle"></i>
                                {renderView()}
                                <br />
                                <Card.Footer className="bg-white">
                                    <div className="card-footer-right">
                                        <div id="MOwnerlike">{this.state.data[0].MLikeCount}</div>
                                        <div id="likeIcon"><img alt="لایک ها" src="/imgs/heart.svg" /></div>
                                    </div>
                                    <div className="card-footer-left">
                                        <div /*onClick="showShare()"*/ data-toggle="tooltip" title="اشتراک گذاری" className="leftIcons"><img alt="اشتراک گذاری" src="/imgs/ic_share.svg" /></div>
                                        <div id="MOwnerComment">{this.state.data[0].MCommentsCount}</div>
                                        <div className="leftIcons">
                                            <img alt="نظرات" src="/imgs/comment.svg" />
                                        </div>
                                        <div className="leftIcons">
                                            <img onClick={this.handleClose} alt="گزارش" src="/imgs/danger.svg" />
                                        </div>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </center>
                    </header>
                </div>
            );
        } else {
            if (this.state.data == 0) {
                return (
                    <header id="home" className="text-center">
                    <center>
                        <Card className="text-dark text-center border-light">
                            <Card.Header className="bg-white post-card-header">
                            </Card.Header>
                                پست مورد نظر پیدا نشد!
                            <Card.Footer className="bg-white">
                            </Card.Footer>
                        </Card>
                    </center>
                </header>
                );
            }
            return (
                <header id="home" className="text-center">
                    <center>
                        <Card className="text-dark text-center border-light">
                            <Card.Header className="bg-white post-card-header">
                                <ContentLoader viewBox="0 0 380 70" rtl={true}>
                                    <circle cx="30" cy="30" r="20" style={{ textAlign: 'right' }} />
                                    <rect x="60" y="17" rx="4" ry="4" width="50" height="8" />
                                    <rect x="60" y="37" rx="4" ry="4" width="100" height="8" />
                                </ContentLoader>
                            </Card.Header>
                            <ContentLoader viewBox="0 0 380 200" rtl={true}>
                                <rect x="60" y="20" rx="4" ry="0" width="260" height="160" />
                            </ContentLoader>
                            <Card.Footer className="bg-white">
                                <div className="card-footer-right">
                                    <div id="likeIcon" style={{ color: 'gray' }}>1+</div>
                                </div>
                                <div className="card-footer-left">
                                    <div /*onClick="showShare()"*/ data-toggle="tooltip" title="اشتراک گذاری" className="leftIcons"><img alt="اشتراک گذاری" src="/imgs/ic_share.svg" style={{ filter: 'invert(1)' }} /></div>
                                    <div className="leftIcons"><img alt="نظرات" style={{ filter: 'invert(1)' }} src="/imgs/comment.svg" /></div>
                                </div>
                            </Card.Footer>
                        </Card>
                    </center>
                </header>
            );
        }
    }
}

export default Posts;