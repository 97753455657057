import React from 'react';

const About = () => {
    return (
        <div>
            شبکه اجتماعی باهم از ۵ قسمت اصلی تشکیل شده است :
            <ul>
                <li>چت روم   : هزاران کاربر آنلاین و آماده برای چت از شهر های مختلف !</li>
                <li>سرگرمی ها : روزانه بیش از ۲۰ هزار بازی بین کاربران انجام می شود !</li>
                <li>گروه ها : بیش از ۷ هزار گروه فعال متفاوت برای چت کردن !</li>
                <li>کانال ها : بیش از ۱۰ هزار کانال فعال فارسی در موضوعات مختلف !</li>
                <li>پست ها   : میلیون ها مطلب مختلف همراه عکس و کلیپ به اشتراک گذاشته شده است.</li>
            </ul>
            "باهم" محیطی شاد و سالم برای با هم بودن !
        </div>
    );
}

export default About;