const initialState = {
    show: true,
};

const reducer = (state=initialState, action) => {

    if(action.type === 'SET_SHOW')
    {
        return {
            ...state,
            show: action.val
        }
    }    
    
};

export default reducer;