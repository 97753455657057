import React from 'react';
// import NotFound from './notFound';
import { Card, Button } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { Helmet } from 'react-helmet';

class Groups extends React.Component {

    constructor () {
        super()
        this.state = {}
    }

    componentDidMount() {
        fetch('https://backend.ba-ham.com/grouplink/' + this.props.match.params.grouplink)
            .then(res => res.json())
            .then((data) => {
                this.setState({data: data});
            });
        
    }

    render(){
        const renderCover = ()=>{
            if (this.state.data.model.gpicture.includes('jpg')){
                return <div className="card-header bg-white" style={{margin: "3px", backgroundImage: `url(${this.state.data.model.gpicture })`, backgroundPosition: "center", paddingTop: "60%", height: 0}}></div>;
            }else{
                return <div className="card-header bg-white" style={{margin: "3px", backgroundImage: `url('https://ba-ham.com/img/baham3.png')`, backgroundPosition: "center", paddingTop: "60%", height: 0}}></div>
            }
        }

        if (this.state.data)
            return (
                <header id="home" className="text-center">
                    <Helmet>
                        <meta property="og:title" content={ this.state.data.model.gname } />
                        <meta property="og:description" content={ this.state.data.model.description } />
                        <meta property="og:type" content='Group' />
                        <meta property="og:image" content={
                            this.state.data.model.gpicture.includes('jpg') ?
                            this.state.data.model.gpicture :
                            'https://ba-ham.com/img/baham3.png'
                        } />
                        <meta property="og:site_name " content='شبکه اجتماعی باهم' />
                    </Helmet>
                    <center>
                        <Card className="text-dark text-center border-light">
                        {renderCover()}
                        <Card.Body className="bg-info-light border-light">
                            <div className="bold-sans-bg text-gray mt-5 username">{ this.state.data.model.gname }<br /></div>
                            <div className="sans text-gray-light" style={{whiteSpace: "pre-line"}}>{ this.state.data.model.description }<br /></div>
                        
                            <div className="container mt-4">
                                <div className="row">
                                    <div className="col">
                                        <Card.Title className="numbers text-gray">{ this.state.data.gr }</Card.Title>
                                        <Card.Title className="text-main-color bold-sans">اعضاء</Card.Title>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row" style={{bottom: 0, margin: "3px"}}>
                                <Button size="lg" variant="primary" className="col-md-6 col-sm-6 col-6 btnRight" href="https://play.google.com/store/apps/details?id=ir.android.baham">دانلود باهم</Button>
                                <Button size="lg" variant="info" className="col-md-6 col-sm-6 col-6 btnLeft" href={this.state.data.link}>بازکردن در باهم</Button>
                            </div>
                        </Card.Body>
                        </Card>
                    </center>
                </header>
            ) 
        else return (
            <header id="home" className="text-center">
                <center>
                    <Card className="text-dark text-center border-light">
                    <div className="card-header bg-white" style={{margin: "3px", backgroundImage: `url('https://ba-ham.com/img/baham3.png')`, backgroundPosition: "center", paddingTop: "60%", height: 0}}></div>
                    <Card.Body className="bg-info-light border-light">
                        <div className="bold-sans-bg text-gray mt-5 username">شبکه اجتماعی باهم<br /></div>
                        <div className="sans text-gray-light" style={{whiteSpace: "pre-line"}}>{(this.state.data === 0) ? '' : 'درحال جستجوی گروه...'}<br /></div>
                    
                        <div className="container mt-4">
                        {(this.state.data === 0)
                            ?
                                <div className="sans text-gray-light">گروه مورد نظر وجود ندارد.
                                <br /><br /></ div>
                            :
                            <ContentLoader>
                                {/* Only SVG shapes */}    
                                <rect x="20" y="40" rx="3" ry="3" width="250" height="10" />
                                <rect x="20" y="70" rx="3" ry="3" width="250" height="10" />
                                <rect x="20" y="100" rx="3" ry="3" width="250" height="10" />
                            </ContentLoader>
                            }
                        </div>
                        <br />
                        <div className="row" style={{bottom: 0, margin: "3px"}}>
                            <Button size="lg" variant="primary" className="col-md-6 col-sm-6 col-6 btnRight" href="https://play.google.com/store/apps/details?id=ir.android.baham">دانلود باهم</Button>
                            <Button size="lg" variant="info" className="col-md-6 col-sm-6 col-6 btnLeft" href="baham://ba-ham.com">بازکردن در باهم</Button>
                        </div>
                    </Card.Body>
                    </Card>
                </center>
            </header>
        );
    }
}

export default Groups;