import React from 'react';

const SectionD = () => {
    return (
        <div className="section light-bg">
            <div className="container">
                <div className="row">
                <div className="col-md-4">
                        <img src={window.location.origin + '/imgs/iphonex.png'} alt="iphone" className="img-fluid" />
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                        <ul className="list-unstyled ui-steps">
                            <li className="media text-right">
                                <div className="circle-icon mr-4">1</div>
                                <div className="media-body mr-3">
                                    <h5>به سادگی عضو بشید!</h5>
                                    <p>با وارد کردن شماره تلفنتون ، خیلی سریع میتونید تو باهم عضو بشید!</p>
                                </div>
                            </li>
                            <li className="media my-4 text-right">
                                <div className="circle-icon mr-4">2</div>
                                <div className="media-body mr-3">
                                    <h5>دوست پیدا کنید!</h5>
                                    <p>تو باهم میتونید کلی دوست جدید پیدا کنید ، یا حتی از دوستای غیرباهمی تون دعوت کنید تا به شما بپیوندن!</p>
                                </div>
                            </li>
                            <li className="media text-right">
                                <div className="circle-icon mr-4">3</div>
                                <div className="media-body mr-3">
                                    <h5>از لحظاتتون لذت ببرید!</h5>
                                    <p>عکس ها ، فیلم ها و حتی آهنگ های مورد علاقتون رو با کاربرای دیگه به اشتراک بذارید!</p>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default SectionD;