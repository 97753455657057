import React from 'react';
import { FaEnvelope } from 'react-icons/fa';

const Address = () => {
    return (
        <div>
            <div><FaEnvelope /> ما همواره از طریق آدرس الکترونیکی <a href="mailto:info@ba-ham.com">info@ba-ham.com</a> آماده شنیدن نقطه نظرات و پیشنهادات شما عزیزان هستیم.</div><br />
        </div>
    );
}

export default Address;