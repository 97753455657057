import React from 'react';
import Header from './header';
import Logos from './logos';
import SectionA from './section-a';
import SectionB from './section-b';
import SectionC from './section-c';
import SectionD from './section-d';
import Pictures from './pictures';
import Faq from './faq';
import Download from './download';

const Home = () => {
    return (
        <div>
            <Header />
            <Logos />
            <SectionA />
            <SectionB />
            <SectionC />
            <SectionD />
            <Pictures />
            <Faq />
            <Download />
        </div>
    );
}

export default Home;