import React from 'react';
import { FaDownload } from 'react-icons/fa';

const Download = () => {
    return (
        <div className="section" id="download">
            <div className="container">
                <div className="call-to-action">

                    <h1 className="text-light"><FaDownload className="mr-3 ti-3x gradient-fill" /></h1>
                    <h2 className="text-primary">همین الان دانلود کن!</h2>
                    <div className="my-4">
                        <a href="https://play.google.com/store/apps/details?id=ir.android.baham"><img src={window.location.origin + '/imgs/googleplay.png'} className="mt-1" alt="icon" /></a>
                        <a href="https://cafebazaar.ir/app/ir.android.baham/"><img src={window.location.origin + '/imgs/cafebazaar.png'} alt="icon" className="mt-1" /></a>
                        <a href="https://myket.ir/app/ir.android.baham/?lang=fa"><img src={window.location.origin + '/imgs/myket.png'} alt="icon" className="mt-1" /></a>
                        {/* <a href="https://iranapps.ir/app/ir.android.baham"><img src={window.location.origin + '/imgs/iranapps.png'} alt="icon" className="mt-1" /></a>
                        <a href="https://www.charkhoneh.com/content/930614182"><img src={window.location.origin + '/imgs/charkhoneh.png'} alt="icon" className="mt-1" /></a> */}
                    </div>
                    <p className="text-primary"><small><i>*حداقل نسخه اندروید مورد نیاز ، ۴ می باشد. </i></small></p>
                </div>
            </div>
            {/* <a referrerPolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=195267&amp;Code=F2qASLpr5uIfnQ9hQTL7"><img referrerPolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=195267&amp;Code=F2qASLpr5uIfnQ9hQTL7" alt="" style={{cursor:"pointer"}} id="F2qASLpr5uIfnQ9hQTL7" /></a> */}
        </div>
    );
};

export default Download;