import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

const SectionB = () => {

    return (
        <div className="section light-bg">
            <div className="container">

                <Tabs defaultActiveKey="communication" className="text-right">
                    <Tab eventKey="communication" title="چت" className="tab-pane fade">
                        <div className="d-flex flex-column flex-lg-row text-right">
                            <div>
                                <h2>دوستایی نزدیک تر از رگ گردن به تو!</h2>
                                <p className="lead">خیلی زود و راحت میتونی کلی دوست پیدا کنی! </p>
                                <p>وقتی تو چت روم باهم وارد میشی ، Location باید لوکیشن گوشیت رو روشن کنی تا باهم خیلی سریع نزدکیترین دوستاتو بهت نشون بده! دوست پیدا کنید ، باهم چت کنید و بعد میتونید تو همه بخش های با هم وقتتونو بگذرونید!
                                </p>
                            </div>
                            <img src={window.location.origin + '/imgs/chat.png'} alt="graphic" className="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0" />
                        </div>
                    </Tab>
                    <Tab eventKey="schedule" title="گروه و کانال" className="tab-pane fade">
                        <div className="d-flex flex-column flex-lg-row text-right">
                            <div>
                                <h2>دوستانتونو دور هم جمع کنید</h2>
                                <p className="lead">گروه و کانال بسازید و عضو جمع کنید! </p>
                                <p>تو باهم ، ساخت گروه و کانال کاملا رایگانه! سه سوت گروه یا کانال بسازید. عضو جمع کنید و بعد کلی خوش بگذرونید!
                                </p>
                            </div>
                            <img src={window.location.origin + '/imgs/groups.png'} alt="graphic" className="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0" />
                        </div>
                    </Tab>
                    <Tab eventKey="messages" title="پست" className="tab-pane fade">
                        <div className="d-flex flex-column flex-lg-row text-right">
                            <div>
                                <h2>اشتراک لحظات زیبای زندگی</h2>
                                <p className="lead">پست های باهم محل اشتراک تصاویر، فیلم ها و نوشته های شما با دوستان نزدکی یا همه کاربرای دیگه با همه. </p>
                                <p>پست هاتون میتونن حاوی فیلم ، عکس و نوشته باشن و دیگران هم میتونن لایک کنن و نظر بذارن. تازه! ما محدویتی واسه زمان فیلم های پست های شما نذاشتیم!
                                </p>
                            </div>
                            <img src={window.location.origin + '/imgs/posts.png'} alt="graphic" className="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0" />
                        </div>
                    </Tab>
                    <Tab eventKey="livechat" title="سرگرمی" className="tab-pane fade">
                        <div className="d-flex flex-column flex-lg-row text-right">
                            <div>
                                <h2>رقابت با دوستان!</h2>
                                <p className="lead">کل کل داری با رفیقات سر اطلاعات عمومی؟ خوب اینجا دقیقا همونجاست که میتونی خودتو ثابت کنی! </p>
                                <p>رقابت سر سوالات اطلاعات عمومی چهار گزینه ای که با یکی از دوستانت یا یه رقیب تصادفی ، حداقل کاری که میکنه محک اطلاعات عمومیته، ماهم برای اینکه رقابت رو جذابتر کنیم به برنده رقابت سکه ای میدیم که میتونه تو باهم ازش کلی استفاده کنه!
                                </p>
                            </div>
                            <img src={window.location.origin + '/imgs/graphic.png'} alt="graphic" className="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0" />
                        </div>
                    </Tab>
                </Tabs>


            </div>
        </div>
    );
};

export default SectionB;